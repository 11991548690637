import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IUser } from '../../@core/user/user.interface';
import { UserService } from '../../@core/user/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnDestroy {
  public url: string;
  public readonly user$: Observable<IUser> = this.authService.user$;
  private readonly destroyed$: Subject<void>;

  constructor(private readonly authService: UserService) {
    this.destroyed$ = new Subject<void>();
    this.subscribeEmitters();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private computeUserAvatar(url: string): string {
    const dimensions = 120;
    if (url.startsWith('data:') || url.startsWith('http') || url.startsWith('//')) {
      if ((url || '').startsWith('https://din8r827idtuo.cloudfront.net/')) {
        const [source, ...query] = url.split('?');
        query.push(`width=${dimensions}`);
        query.push(`height=${dimensions}`);
        // ToDo: move it out
        return source.replace(
          'din8r827idtuo.cloudfront.net',
          'de4khei8i4ut2.cloudfront.net'
        ) + `?${query.join('&')}`;
      }
      if (url.startsWith('https://api.adorable.io/avatars/')) {
        const avatar = url.split('/');
        const path = avatar[avatar.length - 1];
        return `https://api.adorable.io/avatars/${dimensions}/${path}`;
      }
      return url;
    }

    return `https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/${url}_full.jpg`;
  }

  private subscribeEmitters(): void {
    this.user$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (user: IUser) => {
        if (user) {
          this.url = this.computeUserAvatar(user.avatar);
        }
      }
    );
  }
}
