import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { ProfileModule } from '../profile/profile.module';
import { NavigatorModule } from './navigator/navigator.module';
import { SharedModule } from '../shared.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    HeaderComponent
  ],
  exports: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    ProfileModule,
    NavigatorModule
  ]
})
export class HeaderModule {
}
