<div class='header-wrapper'>
  <mat-icon class='toggle-nav-bar' (click)='toggleSideBar()'>menu</mat-icon>

<!--  <div>-->
<!--    <span>name</span>-->

    <app-profile (click)='toggleNavigator()'></app-profile>
<!--  </div>-->

  <app-navigator
    [opened]='navigatorIsOpened'
    [ngClass]="{ 'opened': navigatorIsOpened }"
    (changeOpened)='navigatorEvent($event)'
  ></app-navigator>
</div>
