import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { QueryUtils } from 'src/app/@core/utils/query.util';
import { IUser } from './user.interface';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly user: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(this.storage.get('user') ?? null);
  public readonly user$: Observable<IUser> = this.user.asObservable().pipe(
    tap((user) => this.storage.set('user', user))
  );
  private readonly userRequest: Subject<void> = new Subject<void>();


  constructor(
    private readonly http: HttpClient,
    private router: Router,
    @Inject(LOCAL_STORAGE) private readonly storage: StorageService
  ) {
    this.subscribeEmitters();
  }

  public checkAuthorizedStatus(): boolean {
    return !!this.user.getValue();
  }

  public requestUserProfile(): void {
    this.userRequest.next();
  }

  public clearUserProfile(): void {
    this.user.next(null);
  }

  public requestProfile(): Observable<IUser> {
    return this.http.get<IUser>(`${environment.apiUrl}/users/me/${QueryUtils.paramsToQuery({ detailed: true })}`,
      { withCredentials: true })
      .pipe(catchError((error: HttpErrorResponse) => this.onProfileRequestError(error)));
  }

  public requestRoles(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/users/roles`,
      { withCredentials: true })
      .pipe(catchError((error: HttpErrorResponse) => of([]))); // TODO: убрать, когда будет бэк на hltv для roles
  }

  private subscribeEmitters(): void {

    this.userRequest
      .pipe(
        switchMap(() => combineLatest([this.requestProfile(), this.requestRoles()])),
        filter(([user, role]) => !!user && !!role)
      )
      .subscribe(([user, roles]) => {
        const url = this.router.routerState.snapshot.url;
        if (url === '/login') {
          this.router.navigate(['/']);
        }

        if (environment.projectName === 'CSGOFAST') { // TODO: убрать, когда будет бэк на hltv для roles
          this.user.next({ ...user, roles });
        } else {
          this.user.next({ ...user, roles });
        }
      });
  }


  private onProfileRequestError(e: HttpErrorResponse): Observable<null> {
    return of(null);
  }
}
