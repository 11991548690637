import { Component } from '@angular/core';
import { AuthService } from './@core/auth/auth.service';
import { Title } from '@angular/platform-browser';
import { environment } from '@env/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'admin-panel';
  public toggleSideBar = true;

  public authorized$ = this.authService.authorized$;

  constructor(private authService: AuthService,
              private titleService: Title) {
    this.setDocTitle(environment.projectName);
  }

  public toggleSideMenu() {
    this.toggleSideBar = !this.toggleSideBar;
  }

  setDocTitle(title: string) {
    this.titleService.setTitle(title + ' case panel');
  }

}
