import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,

} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly injector: Injector
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => this.onHttpError(error))
    );
  }

  private onHttpError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 403) {
      this.injector.get(AuthService).switchToUnauthorized();
    }
    console.error(error.error);
    return throwError(error);
  }
}
