import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';

interface INavLink {
  label: string;
  link: string;
  roles: string[];
  index?: number;
  icon?: string;
}

const CSGOFAST_LINKS: INavLink[] = [
  {
    label: 'Cases',
    link: 'cases',
    icon: 'inventory_2',
    roles: ['main_owner']
  },
  {
    label: 'Categories',
    link: 'categories',
    icon: 'category',
    roles: ['main_owner']
  },
  {
    label: 'Free cases',
    link: 'free-cases',
    icon: 'key_off',
    roles: ['main_owner']
  },
  {
    label: 'Upgrade',
    link: 'upgrade',
    icon: 'upgrade',
    roles: ['main_owner']
  },
  {
    label: 'Leaders race',
    link: 'leaders-race',
    icon: 'leaderboard',
    roles: ['main_owner']
  },
  {
    label: 'Discount system',
    link: 'discount-system',
    icon: 'leaderboard',
    roles: ['main_owner']
  },
  {
    label: 'Bonus system',
    link: 'bonus-system',
    icon: 'leaderboard',
    roles: ['main_owner']
  }
];

const HLTV_LINKS: INavLink[] = [
  {
    label: 'Cases',
    link: 'cases',
    icon: 'inventory_2',
    roles: ['main_owner']
  },
  {
    label: 'Categories',
    link: 'categories',
    icon: 'category',
    roles: ['main_owner']
  },
  {
    label: 'Free cases',
    link: 'free-cases',
    icon: 'key_off',
    roles: ['main_owner']
  },
  {
    label: 'Upgrade',
    link: 'upgrade',
    icon: 'upgrade',
    roles: ['main_owner']
  },
  {
    label: 'Samurai',
    link: 'samurai',
    icon: 'sports_esports',
    roles: ['main_owner']
  }
];

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent {
  @Input() opened: boolean;
  public links: INavLink[];
  public environment = environment;

  constructor() {
    this.links = environment.projectName === 'HLTV'
      ? HLTV_LINKS
      : CSGOFAST_LINKS;
  }

  public hideLink(user: any, roles: string[]): boolean {
    return true;
    // return user.roles.some((role: string) => roles.includes(role))
  }
}
