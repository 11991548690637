<div class="modal-container">

  <div class="modal-header">
    <h3>{{title}}</h3>

    <mat-icon class="close" (click)="onClose()">close</mat-icon>
  </div>
  <mat-divider class="divider"></mat-divider>

  <div class="modal-content">
    <ng-content></ng-content>
  </div>

  <ng-container *ngIf="actions">
    <mat-divider class="divider"></mat-divider>
    <div class="modal-actions">
      <button class="btn btn-sm" (click)="onClose()">Отмена</button>
      <button class="btn btn-sm btn-success" (click)="onConfirm()" [disabled]="disabledConfirm">Применить</button>
    </div>
  </ng-container>

</div>
