import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserService } from '../../@core/user/user.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Output() public toggleSidebar: EventEmitter<void> = new EventEmitter<void>();

  public projectName = environment.projectName;
  public navigatorIsOpened: boolean = false;
  constructor(private authService: UserService) {
  }

  public toggleSideBar(): void {
    this.toggleSidebar.emit();
  }

  public toggleNavigator(): void {
    this.navigatorIsOpened = !this.navigatorIsOpened;
  }

  public navigatorEvent(component?: string): void {
    this.closeNavigator();
  }

  public closeNavigator(): void {
    this.navigatorIsOpened = false;
  }

}
