import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const navigatorRootAnimation = trigger('navigatorRootAnimation', [
  transition(':enter', [
    group([
      query(':self', [
        style({ transform: 'translate3d(-100%, 0, 0)', position: 'absolute', top: 0, left: 0, padding: '1.25rem 1rem 1rem', width: '100%', height: '100%' }),
        animate('300ms ease-in', style({ transform: 'translate3d(0, 0, 0)' })),
      ]),
      query('.navigator-close-button', [ style({ opacity: 0 }), ], { optional: true })
    ]),
  ]),
  transition(':leave', [
    group([
      query(':self', [
        style({ transform: 'translate3d(0, 0, 0)', position: 'absolute', top: 0, left: 0, padding: '1.25rem 1rem 1rem', width: '100%', height: '100%' }),
        animate('300ms ease-in', style({ transform: 'translate3d(-100%, 0, 0)' })),
      ]),
      query('.navigator-close-button', [ style({ opacity: 0 }), ], { optional: true })
    ]),
  ]),
]);

export const navigatorChildrenAnimation = trigger('navigatorChildrenAnimation', [
  transition(':enter', [
    query(':self', [
      style({ transform: 'translate3d(100%, 0, 0)', position: 'absolute', top: 0, left: 0, padding: '1.25rem 1rem 1rem', width: '100%', height: '100%' }),
      animate('300ms ease-in', style({ transform: 'translate3d(0, 0, 0)' })),
    ]),
  ]),
  transition(':leave', [
    query(':self', [
      style({ transform: 'translate3d(0, 0, 0)', position: 'absolute', top: 0, left: 0, padding: '1.25rem 1rem 1rem', width: '100%', height: '100%' }),
      animate('300ms ease-in', style({ transform: 'translate3d(100%, 0, 0)' })),
    ]),
  ]),
]);
