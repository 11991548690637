import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, fromEvent, interval, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '../user/user.service';
import { AuthActionTypes } from './auth.interface';
import { IUser } from '../user/user.interface';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly authWindowClosed$: Subject<void> = new Subject<void>();
  private authWindow: Window;

  private readonly authorized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly authorized$: Observable<boolean> = this.authorized.asObservable();

  private user$: Observable<IUser>;
  private redirectPage: string;

  constructor(
    private readonly http: HttpClient,
    private readonly userService: UserService,
    private readonly router: Router
  ) {
    this.authWindowClosed$ = new Subject<void>();
    this.user$ = this.userService.user$;
    this.subscribeEmitters();
    this.userService.requestUserProfile();
  }

  public login(pageToLogin?: string): void {
    this.redirectPage = pageToLogin;
    if (this.authWindow !== undefined) {
      this.authWindow.close();
    }
    const params: Record<string, string | number> = { width: 450, height: 600, resizable: 'yes', scrollbars: 'yes' };
    const url = `${environment.apiAuthUrl}/authorization/steam`;
    this.initAuthWindowMessageListener();
    this.authWindow = window.open(url, 'auth', Object.keys(params).map(key => `${key}=${params[key]}`).join(','));
    this.authWindow.focus();
    this.initAuthWindowStateListener();
  }

  public logout(): void {
    this.http.post(`${environment.apiUrl}/authorization/logout`, {},
      { withCredentials: true }
    ).subscribe(
      async () => {
        this.userService.clearUserProfile();
        await this.router.navigate(['/login']);
      }
    );

  }

  public switchToUnauthorized(): void {
    if (this.authorized.getValue() === false) return;
    this.authorized.next(false);
    this.userService.clearUserProfile();
  }

  public switchToAuthorized(): void {
    if (this.authorized.getValue() === true) return;
    this.authorized.next(true);
  }

  private initAuthWindowMessageListener(): void {
    fromEvent<MessageEvent>(window, 'message').pipe(
      takeUntil(this.authWindowClosed$)
    ).subscribe(
      (e: MessageEvent) => {
        this.handleAuthResult(e);
      }
    );
  }

  private initAuthWindowStateListener(): void {
    interval(1000).pipe(
      takeUntil(this.authWindowClosed$),
      filter(() => this.authWindow.closed)
    ).subscribe(
      () => this.authWindowClosed$.next()
    );
  }

  private handleAuthResult(e: MessageEvent): void {
    // const { code, event, success } = e.data;
    const { success, event, provider, code } = e.data;
    if (provider) {
      if (success) {
        switch (event) {
          case AuthActionTypes.AUTHENTICATION:
            this.requestTokens(code);
            this.authWindow.close();
            break;
          case AuthActionTypes.REGISTRATION:
            this.requestTokens(code);
            this.authWindow.close();
            break;
          default: {
            console.error(`Unknown auth type "${event}"`);
          }
        }
      }
    }
  }

  private requestTokens(code: string): void {
    this.http.post<{ accessToken: string }>(`${environment.apiUrl}/authorization/tokens`, { code },
      { withCredentials: true }
    ).subscribe(
      (data: { accessToken: string }) => {
        this.userService.requestUserProfile();
        this.userService.user$.pipe(filter((user) => Boolean(user)), take(1)).subscribe((user) => {
            // if (user && this.redirectPage) {
            //   this.router.navigate([this.redirectPage]);
            //   this.redirectPage = null;
            // }
            this.router.navigate(['/']);
          }
        );

      }
    );
  }

  private subscribeEmitters(): void {
    this.user$.subscribe((user: IUser) => user === null ? this.switchToUnauthorized() : this.switchToAuthorized());
  }
}
