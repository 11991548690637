<ng-container *ngIf="(user$ | async) as user">
  <ng-container [ngSwitch]="currentPage">
    <div class="navigator-container" *ngSwitchCase="navigatorPages.ROOT" [@navigatorRootAnimation]>

      <div class="navigator-profile">
        <app-profile></app-profile>
        <div class="navigator-profile-info">
          <div class="navigator-profile-name">{{ user.name }}</div>
          <div>id: {{ user.id }}</div>
<!--          <div>role: {{user.roles[0].role}}</div>-->
        </div>
      </div>

      <div class="navigator-list">

        <div class="navigator-divider"></div>
        <div class="button button-link navigator-item" (click)="logout()">
        <span class="navigator-item-name">Выход</span>
          <mat-icon>arrow_forward</mat-icon>
        </div>
      </div>
    </div>

  </ng-container>
</ng-container>
