export class QueryUtils {
  public static paramsToQuery (params?: Record<string, any>): string {
    if (!params) return '';
    const query = Object.keys(params).map((key: string) => {
      const value = params[key];
      if (value === undefined || value === false) return '';
      if (Array.isArray(value)) {
        if (value.length === 0) return '';
        return `${key}=${value.join(',')}`;
      }
      if (typeof value === 'object') {
        return Object.keys(value).map((subkey: string) => `${key}[${subkey}]=${value[subkey]}`).join('&');
      }
      return `${key}=${params[key]}`;
    })
      .filter(result => result)
      .join('&');
    return query === '' ? query : `?${query}`;
  }
}
