<div class="login-wrapper">
  <div class="login-content">
    <mat-card>
      <mat-card-content>
        <button class="social-item-button steam"
                (click)="authorize()">
          <span class="icon-steam"></span>
        </button>
      </mat-card-content>
    </mat-card>
  </div>
</div>
