import {
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { filter, takeUntil } from 'rxjs/operators';
import { navigatorRootAnimation, navigatorChildrenAnimation } from './navigator.animation';
import { fromEvent, Observable, Subject } from 'rxjs';
import { NavigatorPages } from './navigator.interface';
import { UserService } from '../../../@core/user/user.service';
import { AuthService } from '../../../@core/auth/auth.service';

@Component({
  selector: 'app-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: [
    './navigator.component.scss',
    './navigator-list.component.scss'
  ],
  animations: [navigatorRootAnimation, navigatorChildrenAnimation]
})
export class NavigatorComponent implements OnChanges {
  @HostBinding('class')
  public currentPage: NavigatorPages = NavigatorPages.ROOT;
  public navigatorPages: typeof NavigatorPages = NavigatorPages;
  public readonly user$: Observable<any> = this.userService.user$;

  @Output() public changeOpened: EventEmitter<string> = new EventEmitter<string>();
  @Input() public opened: boolean = false;

  private opened$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly authService: AuthService,
    private readonly userService: UserService,
    private readonly fb: UntypedFormBuilder,
    private readonly dialog: MatDialog,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.subscribeEmitters();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.opened.currentValue === false) {
      this.opened$.next();
    } else {
      setTimeout(() => this.subscribeEmitters(), 0);
    }
  }

  public logout(): void {
    this.authService.logout();
  }

  public close(): void {
    this.changeOpened.emit();
  }

  public changePage(page: NavigatorPages): void {
    this.currentPage = page;
  }

  public changeComponent(component: string): void {
    this.changeOpened.emit(component);
  }

  private subscribeEmitters(): void {
    fromEvent(this.document, 'click').pipe(
      takeUntil(this.opened$),
      filter((event: any) => !(event.target.closest('app-navigator') instanceof HTMLElement))
    ).subscribe(
      () => this.close()
    );
  }
}
