import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from './@core/auth/auth.interceptor';
import { AuthModule } from './@core/auth/auth.module';
import { AuthGuardService } from './@core/auth/auth-guard.service';
import { SharedModule } from './@shared/shared.module';
import { HeaderModule } from './@shared/header/header.module';
import { SideBarComponent } from './@shared/side-bar/side-bar.component';



@NgModule({
  declarations: [
    AppComponent,
    SideBarComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    AuthModule,
    HeaderModule
  ],
  providers: [
    Title,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  exports: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
