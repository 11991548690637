import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-window',
  templateUrl: './modal-window.component.html',
  styleUrls: ['./modal-window.component.scss']
})
export class ModalWindowComponent {
  @Input() public title: string = '';
  @Input() public actions: boolean;
  @Input() public disabledConfirm: boolean = false;
  @Output() public close: EventEmitter<void> = new EventEmitter<void>();
  @Output() public confirm: EventEmitter<void> = new EventEmitter<void>();

  public onClose(): any {
    this.close.emit();
  }

  public onConfirm(): any {
    this.confirm.emit();
  }
}
