import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NavigatorComponent } from './navigator.component';
import { ProfileModule } from '../../profile/profile.module';
import { SharedModule } from '../../shared.module';



@NgModule({
  declarations: [
    NavigatorComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ProfileModule,
    SharedModule
  ],
  exports: [
    NavigatorComponent,
  ],
})
export class NavigatorModule {
}
