import { EventEmitter } from '@angular/core';

export enum NavigatorPages {
  ROOT = 'root',
  BALANCE = 'balance',
  REFERRAL = 'referral',
  SETTINGS = 'settings',
  NOTIFICATIONS = 'notifications',
  PREFERENCES = 'preferences',
  LANGUAGE = 'language',
  TRANSACTIONS_HISTORY = 'transactions history',
}

export enum NavigatorSettingsPages {
  ROOT = 'root',
  COMMON = 'common',
  APPLICATION = 'application',
  NOTIFICATION = 'notification',
}

export interface NavigatorChildren {
  changePage: EventEmitter<void>;
  navigate: EventEmitter<void>
}
