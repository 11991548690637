<ng-container *ngIf='(authorized$ | async) as user; else unauthorized'>

  <app-side-bar class='side-bar'
                [class.opened]='toggleSideBar'
                [opened]='toggleSideBar'
  ></app-side-bar>

  <div class='main'>
    <app-header (toggleSidebar)='toggleSideMenu()' class='header'></app-header>

    <div class='main-container'>
      <router-outlet></router-outlet>
    </div>
  </div>


</ng-container>

<ng-template #unauthorized>
  <app-auth></app-auth>
</ng-template>
