import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterText'
})
export class FilterTextPipe implements PipeTransform {
  transform<T>(items: T[], searchText: string): T[] {
    if (!items) return [];
    if (!searchText) return items;

    searchText = searchText.toLowerCase();
    return items.filter((item: any) => {
      return item.name.toLowerCase().includes(searchText);
    });
  }
}
