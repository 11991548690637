import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './@core/auth/auth.component';
import { AuthGuardService } from './@core/auth/auth-guard.service';
import { RoleGuardService } from './@core/guards/role-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/cases',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: AuthComponent
  },
  {
    path: 'cases',
    loadChildren: () => import('./@modules/+cases/cases.module').then(m => m.CasesModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'categories',
    loadChildren: () => import('./@modules/+categories/categories.module').then(m => m.CategoriesModuleModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: { roles: ['cases_owner', 'cases_admin'] }
  },
  {
    path: 'free-cases',
    loadChildren: () => import('./@modules/+free-cases/free-cases.module').then(m => m.FreeCasesModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: { roles: ['cases_owner', 'cases_admin'] }
  },
  {
    path: 'upgrade',
    loadChildren: () => import('./@modules/+upgrade/upgrade.module').then(m => m.UpgradeModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: { roles: ['cases_owner', 'cases_admin'] }
  },
  {
    path: 'samurai',
    loadChildren: () => import('./@modules/+samurai/samurai.module').then(m => m.SamuraiModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: { roles: ['cases_owner', 'cases_admin'] }
  },
  {
    path: 'leaders-race',
    loadChildren: () => import('./@modules/+leaders-race/leaders-race.module').then(m => m.LeadersRaceModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: { roles: ['cases_owner', 'cases_admin'] }
  },
  {
    path: 'discount-system',
    loadChildren: () => import('./@modules/discount/discount.module').then(m => m.DiscountModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: { roles: ['cases_owner', 'cases_admin'] }
  },
  {
    path: 'bonus-system',
    loadChildren: () => import('./@modules/bonus/bonus.module').then(m => m.BonusModule),
    canActivate: [AuthGuardService, RoleGuardService],
    data: { roles: ['cases_owner', 'cases_admin'] }
  },
  {
    path: '**',
    redirectTo: '/cases'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}


